import * as React from 'react';
import { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../context/AuthProvider';
import desktopImage from '../images/logo_central-desktop.png'
import CustomDialog from './CustomDialog';
import LoginService from '../services/LoginService';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://scannseed.com/" target="_blank" >ScannSeed</Link>
      {' '}{new Date().getFullYear()}{'.'}
    </Typography>
  );
}

const theme = createTheme();

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login } = useAuth()
  const [error, setMsgError] = useState("")

  const handleLogin = async (event) => {
    setMsgError("")
    event.preventDefault()
    try {
      const user = await LoginService.login({
        email,
        password
      })

      if (user.data.success) {
        login(user)
      }
      else {
        setMsgError(user.data.message)
      }
    } catch (error) {
      {error == "TypeError: Cannot read properties of undefined (reading 'status')" ? setMsgError("Servidor no disponible") : setMsgError(error)}
    }
  };

  // setTimeout(() => {
  //   alert(error)
  // }, 5000)

  return (
    <>
      {error === "" ? "" : <CustomDialog message={error} btnText="OK" title="Aviso" />}
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid item xs={false} sm={false} md={9} sx={{ backgroundImage: `url(${desktopImage})`, backgroundRepeat: 'no-repeat', backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900], backgroundPosition: 'center' }} />
          <Grid item xs={12} sm={12} md={3} component={Paper} elevation={6} square>
            <Box sx={{ my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
              <Avatar sx={{ m: 1, bgcolor: '#A0C516' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">Ingresa a tu cuenta</Typography>
              <Box component="form" noValidate onSubmit={handleLogin} sx={{ mt: 1 }}>
                <TextField margin="normal" required fullWidth id="email" label="Usuario" name="email" autoComplete="email" value={email} autoFocus onChange={({ target }) => setEmail(target.value)} />
                <TextField margin="normal" required fullWidth name="password" label="Contraseña" type="password" id="password" autoComplete="current-password" value={password} onChange={({ target }) => setPassword(target.value)} />
                <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Recordarme" />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>INGRESAR</Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">¿Olvidaste tu contraseña?</Link>
                  </Grid>
                  {/* <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid> */}
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}

export default Login