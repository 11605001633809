import { useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';

export const LogOut = () => {
  const { logout } = useAuth()
  return (
    useEffect(() => {
      logout()
    }, [])
  )
}