import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

export default function MediaCard({id, descripcion, info, imagen, txtButton, navegar}) {
    const navigate = useNavigate();
    return (
      <Card sx={{ maxWidth: 600 }}>
        <CardMedia
          component="img"
          height="180"
          image={`/${imagen}`}
          alt="silo"
          key={id}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {info.nombre}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {descripcion}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => navigate(`${navegar}/${id}`)}>{txtButton}</Button>
        </CardActions>
      </Card>
    );
  }