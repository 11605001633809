import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import dataService from '../services/Data'
import PropTypes from 'prop-types'

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(window.localStorage.getItem('loggedNoteAppUser') ? JSON.parse(window.localStorage.getItem('loggedNoteAppUser')) : null) //useLocalStorage('loggedNoteAppUser', null)

  const login = useCallback((user) => {
    setUser(user)
    dataService.setToken(user.data.token)    
    window.localStorage.setItem('loggedNoteAppUser', JSON.stringify(user))
  }, [])

  const logout = useCallback(() => {
    setUser(null)
    dataService.setToken(null)
    window.localStorage.removeItem('loggedNoteAppUser')
  }, [])

  const value = useMemo(() => ({
    login,
    logout,
    user
  }), [login, logout, user])

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}

AuthContext.propTypes = {
  children: PropTypes.object
};