import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { format } from "date-fns"
import NavAppBar from "../components/NavAppBar"
import GraficoLinea from "../components/GraficoLinea"
import CustomPaginationActionsTable from "../components/TablePagination"
import GraficoLineas from "../components/GraficoLineas"
import DatePicker from "../components/DatePicker"
import { FinDia } from "../components/FinDia"
import { InicioDia } from "../components/InicioDia"
import { useAuth } from "../context/AuthProvider"
import dataService from '../services/Data'
import { Button, Container, CssBaseline, Grid } from "@mui/material"
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TableChartIcon from '@mui/icons-material/TableChart';

export const Datos = () => {
    const params = useParams()
    const { user } = useAuth()
    const [datosTon, setDatosTon] = useState(null)
    const [datosTH, setDatosTH] = useState(null)
    const [tableButtonActiveTon, setTableButtonStateTon] = useState(true)
    const [chartButtonActiveTon, setChartButtonStateTon] = useState(false)

    const [tableButtonActiveTH, setTableButtonStateTH] = useState(true)
    const [chartButtonActiveTH, setChartButtonStateTH] = useState(false)
    const [fechaIniTon, setFechaIniTon] = useState(new Date())
    const [fechaFinTon, setFechaFinTon] = useState(new Date())
    const [fechaIniTH, setFechaIniTH] = useState(new Date())
    const [fechaFinTH, setFechaFinTH] = useState(new Date())
    const [role, setRole] = useState(null)

    const handleChartClickTon = () => {
        setChartButtonStateTon(true)
        setTableButtonStateTon(false)
    };

    const handleDataClickTon = () => {
        setTableButtonStateTon(true)
        setChartButtonStateTon(false)
    };

    const handleChartClickTH = () => {
        setChartButtonStateTH(true)
        setTableButtonStateTH(false)
    };

    const handleDataClickTH = () => {
        setTableButtonStateTH(true)
        setChartButtonStateTH(false)
    };
    
    dataService.setToken(user.data.token)
    useEffect(() => {
        dataService.listarDatosTonByIdSilo(`${params.id}`, setDatosTon)
        dataService.listarDatosTHByIdSilo(`${params.id}`, setDatosTH)
        setRole(user.data.rol)
    }, [])
   
    return (
        <>
            <CssBaseline />
            <NavAppBar />
            <Container style={{ maxWidth: 750 }} >
                <h2 style={{ color: '#009CDE' }}>DATOS Silo {datosTon != null && datosTon.result.datosTon.length !== 0 ? datosTon.result.datosTon[0].nombreSilo : ""}</h2>
                {datosTon != null && datosTon.result.datosTon.length !== 0 && (role === "administrador" || role === "supervisor") ? (
                    <>
                        <h3 style={{ float:'left', height:20  }}>Toneladas</h3>
                        <Button onClick={handleChartClickTon} variant="outlined" style={{ borderColor: 'rgb(0, 158, 226)', marginLeft: 1, float: 'right', height: 40 }}>
                            <ShowChartIcon />
                        </Button>
                        <Button onClick={handleDataClickTon} variant="outlined" style={{ borderColor: 'rgb(0, 158, 226)', marginLeft: 1, float: 'right', height: 40 }}>
                            <TableChartIcon />
                        </Button>
                        <span style={{ marginLeft: 1, float: 'right', width: '16%' }}><DatePicker text="Fecha Fin" value={fechaFinTon} setValue={setFechaFinTon} /></span>
                        <span style={{ float: 'right', width: '16%' }}><DatePicker text="Fecha Ini" value={fechaIniTon} setValue={setFechaIniTon} /></span>
                        <Grid className="contenedor" container spacing={1}>
                            {datosTon != null ? (
                                <>
                                    {tableButtonActiveTon &&
                                        <CustomPaginationActionsTable rows={datosTon.result.datosTon.sort((a, b) => a.fechaHora > b.fechaHora ? -1 : 1).filter((row) => (new Date(row.fechaHora) > InicioDia(fechaIniTon) && new Date(row.fechaHora) <= FinDia(fechaFinTon)))} columns={[{ id: 'nombrePlanta', label: 'Planta', minWidth: 170, align: 'left' }, { id: 'fechaHora', label: 'Fecha', minWidth: 60, align: 'center', format: (value) => format(new Date(value), 'dd/MM/yyyy kk:mm') }, { id: 'ton', label: 'Toneladas', minWidth: 60, align: 'right' }]} />}
                                    {chartButtonActiveTon &&
                                        <GraficoLinea titulo={"Toneladas"} info={datosTon.result.datosTon.sort((a, b) => a.fechaHora > b.fechaHora ? 1 : -1).flatMap((row) => (new Date(row.fechaHora) > InicioDia(fechaIniTon) && new Date(row.fechaHora) <= FinDia(fechaFinTon)) ? row.ton : [])} labels={datosTon.result.datosTon.sort((a, b) => a.fechaHora > b.fechaHora ? 1 : -1).map((dato) => format(new Date(dato.fechaHora), 'dd/MM kk:mm'))} />}
                                </>
                            ) : ""} {/*('No hay datos para el silo seleccionado.')*/}

                        </Grid>
                        <br />
                    </>) : ""}
                {datosTH != null && datosTH.result.datosTH.length !== 0 ? (
                    <>
                        <h3 style={{ float:'left', height:20 }}>Temperatura y Humedad</h3>
                        <Button onClick={handleChartClickTH} variant="outlined" style={{ borderColor: 'rgb(0, 158, 226)', marginLeft: 1, float: 'right', height: 40 }}>
                            <ShowChartIcon />
                        </Button>
                        <Button onClick={handleDataClickTH} variant="outlined" style={{ borderColor: 'rgb(0, 158, 226)', marginLeft: 1, float: 'right', height: 40 }}>
                            <TableChartIcon />
                        </Button>
                        <span style={{ marginLeft: 1, float: 'right', width: '16%' }}><DatePicker text="Fecha Fin" value={fechaFinTH} setValue={setFechaFinTH} /></span>
                        <span style={{ float: 'right', width: '16%' }}><DatePicker text="Fecha Ini" value={fechaIniTH} setValue={setFechaIniTH} /></span>
                        <Grid container spacing={1}>
                            {datosTH != null ? (
                                <>
                                    {tableButtonActiveTH &&
                                        <CustomPaginationActionsTable rows={datosTH.result.datosTH.sort((a, b) => a.fechaHora > b.fechaHora ? -1 : 1).filter((row) => (new Date(row.fechaHora) > InicioDia(fechaIniTH) && new Date(row.fechaHora) <= FinDia(fechaFinTH)))} columns={[{ id: 'nombrePlanta', label: 'Planta', minWidth: 170, align: 'left' }, { id: 'fechaHora', label: 'Fecha', minWidth: 60, align: 'center', format: (value) => format(new Date(value), 'dd/MM/yyyy kk:mm') }, { id: 'temp', label: 'Temperatura', minWidth: 60, align: 'right' }, { id: 'hum', label: 'Humedad', minWidth: 60, align: 'right' }]} />}
                                    {chartButtonActiveTH &&
                                        <GraficoLineas titulo1={"Humedad"} info1={datosTH.result.datosTH.sort((a, b) => a.fechaHora > b.fechaHora ? 1 : -1).flatMap((row) => (new Date(row.fechaHora) > InicioDia(fechaIniTH) && new Date(row.fechaHora) <= FinDia(fechaFinTH)) ? row.hum : [])} titulo2={"Temperatura"} info2={datosTH.result.datosTH.sort((a, b) => a.fechaHora > b.fechaHora ? 1 : -1).flatMap((row) => (new Date(row.fechaHora) > InicioDia(fechaIniTH) && new Date(row.fechaHora) <= FinDia(fechaFinTH)) ? row.temp : [])} labels={datosTH.result.datosTH.sort((a, b) => a.fechaHora > b.fechaHora ? 1 : -1).map((dato) => format(new Date(dato.fechaHora), 'dd/MM kk:mm'))} />}
                                </>
                            ) : ""} {/*('No hay datos para el silo seleccionado.')*/}
                        </Grid>
                        <br />
                    </>
                ) : ""}
            </Container>
        </>
    )
}