import {useAuth} from '../../context/AuthProvider'
import { Navigate, Outlet } from "react-router-dom"

export default function PublicRoute() {
    const {user} = useAuth()

    if(user) {
        return <Navigate to='/'/>
    }

    return (
        <>
            <Outlet />
        </>
    )
}