import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useMemo } from 'react';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    stacked: false,
    plugins: {
        title: {
            display: false,
            text: 'Chart.js Line Chart - Multi Axis',
        },
    },
    scales: {
        y: {
            type: 'linear',
            display: true,
            position: 'left',
            ticks: { color: 'rgb(128, 180, 10)' },
        },
        y1: {
            type: 'linear',
            display: true,
            position: 'right',
            ticks: { color: 'rgb(0, 158, 226)' },
            grid: {
                drawOnChartArea: false,
            },
        },
    },
};

export default function GraficoLineas({ titulo1, info1, titulo2, info2, labels }) {
    const data = {
        datasets: [
            {
                label: titulo1,
                data: info1,
                tension: 0.3,
                borderColor: 'rgb(128, 180, 10)',
                backgroundColor: 'rgba(128, 180, 10, 0.5)',
                yAxisID: 'y',
            },
            {
                label: titulo2,
                data: info2,
                tension: 0.3,
                borderColor: 'rgb(0, 158, 226)',
                backgroundColor: 'rgba(0, 158, 226, 0.5)',
                yAxisID: 'y1',
            },
        ],
        labels
    };

    return <Line data={data} options={options} />
}
