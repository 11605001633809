import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function TextBox({ bigSize, requerido, id, etiqueta, valorDefecto, margen, soloLectura }) {
    return (<TextField
        fullWidth={bigSize}
        required={requerido}
        id={id}
        label={etiqueta}
        defaultValue={valorDefecto}
        margin={margen}
        InputProps={{
            readOnly: soloLectura,
          }}
    />)
}