import { Box, CssBaseline } from "@mui/material"
import TextBox from "../components/TextBox"
import NavAppBar from "../components/NavAppBar"
import { Container } from "@mui/system"
import { useAuth } from "../context/AuthProvider"

export const Perfil = () => {
    const { user } = useAuth()
    const data = user.data
    return (
        <>
            <CssBaseline />
            <NavAppBar />
            <Container style={{ maxWidth: 1400 }} >
                <h2 style={{ color: '#009CDE' }}>Perfil</h2>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextBox margen="dense" soloLectura={true} bigSize={false} requerido={false} id="1" etiqueta="Código" valorDefecto={data.clienteId} />
                    <TextBox margen="dense" soloLectura={true} bigSize={false} requerido={false} id="2" etiqueta="Nombre y Apellido" valorDefecto={data.nombreApellido} />
                    <TextBox margen="dense" soloLectura={true} bigSize={false} requerido={false} id="3" etiqueta="Empresa" valorDefecto={data.empresa} />
                    <TextBox margen="dense" soloLectura={true} bigSize={false} requerido={false} id="4" etiqueta="Usuario" valorDefecto={data.usuario} />
                    <TextBox margen="dense" soloLectura={true} bigSize={false} requerido={false} id="5" etiqueta="Email" valorDefecto={user.data.email} />
                </Box>
            </Container>
        </>
    )
}