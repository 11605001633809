import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import NavAppBar from "../components/NavAppBar"
import { useAuth } from "../context/AuthProvider"
import dataService from '../services/Data'
import { Container, CssBaseline, Grid } from "@mui/material"
import MediaCard from "../components/MediaCard"

export const Silos = () => {
  const params = useParams()
  const { user } = useAuth()
  const [silos, setSilos] = useState(null)

  dataService.setToken(user.data.token)

  useEffect(() => {
    dataService.listarSilosByIdPlanta(`${params.id}`, setSilos)
  }, [])

  return (
    <>
      <CssBaseline />
      <NavAppBar />
      <Container maxWidth="xl">
        <h2 style={{ color: '#009CDE' }}>SILOS {silos != null && silos.result.silos.length !== 0 ? silos.result.silos[0].planta : ""}</h2>
        <Grid container spacing={3}>
          {silos != null && silos.result.silos.length !== 0 ? (
            silos.result.silos.map((silo) => (
              <Grid key={silo.idSilo} item xs={12} sm={6} md={6} xl={3}>
                <MediaCard id={silo.idSilo} descripcion={<span><b>Temperatura:</b> {silo.ultTemp}º<br /><b>Humedad:</b> {silo.ultHum / 10}%<br /><b>Toneladas:</b> {silo.ultTon}</span>} info={silo} imagen='silo.jpg' txtButton='VER DATOS' navegar='/datossilo' />
              </Grid>
            ))) : ""} {/*('no hay silos')*/}
        </Grid>
      </Container>
    </>
  )
}