import axios from 'axios'

const clientesURL = './clientes/listadoClientes'
const plantasByIdClienteURL = './datos/listadoPlantas'
const silosByIdPlantaURL = '/datos/listadoSilos'
const datosTonByIdSiloURL = '/datos/listadoDatosTon'
const datosTHByIdSiloURL = '/datos/listadoDatosTH'
let token = null
const setToken = (newToken) => {
    token = `Bearer ${newToken}`
}

const getConfig = () => {
    return (
        {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        }
    )
}

const listarClientes = async (state) => {
    try {
        const data = await axios.get(clientesURL, getConfig())
        state(data.data)
    }
    catch (e) {
        if (e.response.status === 401) {
            return window.location.href = '/logout'
        }
    }
}

const listarPlantasByIdCliente = async (_idCliente, state) => {
    try {
        const data = await axios.get(`${plantasByIdClienteURL}/${_idCliente}`, getConfig())
        state(data.data)
    }
    catch (e) {
        if (e.response.status === 401) {
            return window.location.href = '/logout'
        }
    }
}

const listarSilosByIdPlanta = async (_idPlanta, state) => {
    try {
        const data = await axios.get(`${silosByIdPlantaURL}/${_idPlanta}`, getConfig())
        state(data.data)        
    }
    catch (e) {
        if (e.response.status === 401) {
            return window.location.href = '/logout'
        }
    }
}

const listarDatosTonByIdSilo = async (_idSilo, state) => {
    try {
        const data = await axios.get(`${datosTonByIdSiloURL}/${_idSilo}`, getConfig())
        state(data.data)
    }
    catch (e) {
        if (e.response.status === 401) {
            return window.location.href = '/logout'
        }
    }
}

const listarDatosTHByIdSilo = async (_idSilo, state) => {
    try {
        const data = await axios.get(`${datosTHByIdSiloURL}/${_idSilo}`, getConfig())
        state(data.data)
    }
    catch (e) {
        if (e.response.status === 401) {
            return window.location.href = '/logout'
        }
    }
}

// eslint-disable-next-line
export default { listarClientes, listarPlantasByIdCliente, listarSilosByIdPlanta, listarDatosTonByIdSilo, listarDatosTHByIdSilo, setToken }