import { CssBaseline } from '@mui/material'
import NavAppBar from '../components/NavAppBar'
import desktopImage from '../images/logo_central-desktop.png'
import mobileImage from '../images/logo_central-mobile.png'
import { useEffect, useState } from 'react'

export const Home = () => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(window.innerWidth < 740 ? true : false)
    const handleWindowResize = () => {
      const bool = window.innerWidth < 740 ? true : false;
      setMobile(bool)
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <NavAppBar />
      {/* <div style={{ padding: 0, margin: 0, height: '90vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container style={{ backgroundImage: `url(${imageUrl})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '65%', width: '65%' }}></Container>
      </div> */}
      <div className="home-content" style={{ backgroundImage: `url(${isMobile ? mobileImage : desktopImage}`, height: '90vh', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
      </div>
    </>
  )
}