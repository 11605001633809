import {useAuth} from '../../context/AuthProvider'
import { Navigate, Outlet } from "react-router-dom"

export default function ProtectedRoute() {
    const {user} = useAuth()

    if(!user) {
      return <Navigate to="/login" />;
    }

    return (
        <>
            <Outlet />
        </>
    )
}