import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useMemo } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: '',
    },
  },
};

export default function GraficoLinea({ titulo, info, labels }) {
  const data = {
      datasets: [
        {
          label: titulo,
          data: info,
          tension: 0.3,
          borderColor: 'rgb(0, 158, 226)',
          backgroundColor: 'rgba(0, 158, 226, 0.5)',          
        },
      ],
      labels
    };
  
  return <Line data={data} options={options} />
}