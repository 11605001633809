import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/es-mx';

export default function DatePicker({ text, value, setValue }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
            <Stack>
                <MobileDatePicker
                    label={text}
                    inputFormat="DD/MM/YYYY"
                    value={value}
                    onChange={(newValue) => { setValue(new Date(newValue)) }}
                    renderInput={(params) => <TextField size="small" {...params} sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'rgb(0, 158, 226)',
                            },
                        }
                    }} />}
                />
            </Stack>
        </LocalizationProvider>)
}