import axios from 'axios'

const baseUrl = './usuario/login'

const login = async credentials => {
    try {
        const data = await axios.post(baseUrl, credentials, { headers: { 'Content-Type': 'application/json' } })
        return data
    }
    catch (e) {
        if (e.response.status === 401) {
            return window.location.href = '/logout'
        }
        else if(e.code === "ERR_NETWORK"){
            alert("Servidor no disponible en este momento.")
        }

    }
}

export default { login }