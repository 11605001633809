import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Login from './components/Login'
import { Home } from './pages/Home';
import { Plantas } from './pages/Plantas'

import { AuthProvider } from './context/AuthProvider';
import PublicRoute from './components/router/PublicRoute';
import ProtectedRoute from './components/router/ProtectedRoute';
import { LogOut } from './components/LogOut';
import { Silos } from './pages/Silos';
import { Datos } from './pages/Datos';
import { Perfil } from "./pages/Perfil";

export default function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<PublicRoute />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route index element={<Home />} />
              <Route path="/plantas" element={<Plantas />} />
              <Route path="/silosplanta/:id" element={<Silos />} />
              <Route path="/datossilo/:id" element={<Datos />} />
              <Route path="/logout" element={<LogOut />} />
              <Route path="/perfil" element={<Perfil />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}