import { useEffect, useState } from 'react'
import MediaCard from '../components/MediaCard';
import { useAuth } from '../context/AuthProvider'
import dataService from '../services/Data'
import NavAppBar from '../components/NavAppBar'
import { CssBaseline, Grid } from '@mui/material';
import { Container } from '@mui/system';

export const Plantas = () => {
  const { user } = useAuth()
  const [plantas, setPlantas] = useState(null)

  dataService.setToken(user.data.token)
  
  useEffect(() => {
    { user.data.rol === "administrador" ? dataService.listarPlantasByIdCliente(0, setPlantas) : dataService.listarPlantasByIdCliente(user.data.clienteId, setPlantas) }
  }, [])
  // if (plantas === null) {
  //   return (<></>)
  // setTimeout(() => {
  //   alert("El cliente no tiene plantas asociadas")
  // }, 1000))
  // }
  // else {
  return (
    <div>
      <CssBaseline />
      <NavAppBar />
      <Container maxWidth="xl">
        <h2 style={{ color: '#009CDE' }}>PLANTAS</h2>
        <Grid container spacing={3}>
          {plantas != null ? (
            plantas.result.plantas.map((planta) => (
              <Grid key={planta.idPlanta} item xs={12} sm={6} md={6} xl={3}>
                <MediaCard key={planta.idPlanta} id={planta.idPlanta} descripcion={<span><b>Temperatura:</b> {planta.temp}º<br /><b>Humedad:</b> {planta.humedad}%</span>} info={planta} imagen='planta.png' txtButton='VER SILOS' navegar='/silosplanta' />
              </Grid>
            ))) : ""} {/*('no hay plantas')*/}
        </Grid>
      </Container>
    </div>
  )
  // }
}